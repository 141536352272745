<template>
  <div>
    <div class="button-switch" v-show="showDebtDetails">
      <label for="enable-disable" class="switch"
        ><input
          type="checkbox"
          id="enable-disable"
          v-model="payingOffDebts" /><span class="slider round"></span></label
      ><span class="switch-text">Paying off debts</span>
    </div>
    <div class="more-info-payment-breakdown-table">
      <table
        id="loan-purpose-refinance-table"
        role="table"
        aria-busy="false"
        aria-colcount="3"
        class="table b-table"
      >
        <thead role="rowgroup" class="thead-info-active">
          <tr role="row" class="">
            <th
              v-for="(header, index) in tableHeaders"
              :key="index"
              role="columnheader"
              scope="col"
              :aria-colindex="index + 1"
              class="table-b-table-default"
              :class="
                header.isCurrentMortgage
                  ? 'position-relative add-dynamic-border-head'
                  : ''
              "
            >
              <div>{{ header.name }}</div>
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup" class="more-info-payment-breakdown-table-active">
          <tr v-for="(rows, rowindex) in tableRows" :key="rowindex" role="row">
            <template v-for="(value, key, cellindex) in rows">
              <td
                v-if="
                  typeof value === 'object' &&
                  !Array.isArray(value) &&
                  value !== null
                "
                :key="cellindex"
                aria-colindex="1"
                role="cell"
                :class="[
                  currentMortgageIndex >= 0 &&
                  cellindex === currentMortgageIndex + 1
                    ? 'add-dynamic-border-body'
                    : '',
                  rowindex === tableRows.length - 1 &&
                  currentMortgageIndex >= 0 &&
                  cellindex === currentMortgageIndex + 1
                    ? 'add-dynamic-border-body-last-el'
                    : '',
                ]"
              >
                <div
                  v-for="(subvalue, key, subcellindex) in value"
                  :key="subcellindex"
                  :class="
                    subvalue === 'Additional Debts'
                      ? 'd-flex justify-space-between'
                      : 'add-margin-top'
                  "
                >
                  <span
                    :class="
                      subvalue === 'Additional Debts'
                        ? ''
                        : 'extra-debt-details'
                    "
                    >{{ convertIfNumber(subvalue) | currencyOrPercent }}</span
                  >
                  <button
                    v-if="subvalue === 'Additional Debts'"
                    type="button"
                    @click="$emit('click')"
                  >
                    <plus-icon v-if="!showDebtTooltip" />
                    <minus-icon v-if="showDebtTooltip" />
                  </button>

                  <div
                    v-if="
                      subvalue !== 'Additional Debts' &&
                      typeof convertIfNumber(subvalue) === 'string'
                    "
                    class="tooltip-container"
                  >
                    <tooltip-icon
                      @mouseenter.native="
                        fetchTooltipDetails(subvalue, subcellindex)
                      "
                      @mouseleave.native="
                        tooltipIndex = null;
                        tooltipObject = {};
                      "
                    />
                    <div class="tooltip" v-show="tooltipIndex === subcellindex">
                      <strong>{{ tooltipObject.creditor }}</strong>
                      Amount: ${{ tooltipObject.balance }} <br />
                      Rate: {{ tooltipObject.rate }}%<br />
                      Payment: ${{ tooltipObject.payment }} <br />
                    </div>
                  </div>
                </div>
              </td>
              <td
                v-else
                :key="cellindex"
                aria-colindex="1"
                role="cell"
                :class="[
                  currentMortgageIndex >= 0 &&
                  cellindex === currentMortgageIndex + 1
                    ? 'add-dynamic-border-body'
                    : '',
                  rowindex === tableRows.length - 1 &&
                  currentMortgageIndex >= 0 &&
                  cellindex === currentMortgageIndex + 1
                    ? 'add-dynamic-border-body-last-el'
                    : '',
                ]"
              >
                <div
                  v-if="value === 'Additional Debts'"
                  class="d-flex justify-space-between"
                >
                  <span>{{ value | currencyOrPercent }}</span>
                  <button type="button" @click="$emit('click')">
                    <plus-icon v-if="!showDebtTooltip" />
                    <minus-icon v-if="showDebtTooltip" />
                  </button>
                </div>
                <span
                  v-else
                  :class="
                    rowindex === tableRows.length - 1 && cellindex !== 0
                      ? 'custom-badge'
                      : ''
                  "
                  >{{ value | currencyOrPercent }}</span
                >
                <info-tooltip
                  v-if="value === 'PMI' || value === 'HOI'"
                  :info="value"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import InfoTooltip from "@/components/Icons/InfoToolTip";
import PlusIcon from "./../Icons/PlusIcon";
import MinusIcon from "./../Icons/MinusIcon";
import TooltipIcon from "./../Icons/TooltipIcon";
import Shades from "@/mixins/shades";
import { mapGetters } from "vuex";
import { REFINANCE_LOAN_TYPE } from "./../../constants";

export default {
  components: { InfoTooltip, PlusIcon, MinusIcon, TooltipIcon },
  mixins: [Shades],
  props: {
    allScenarios: {
      type: Array,
      default: () => [],
    },
    currentMortgageIndex: {
      type: Number,
      default: 0,
    },
    showDebtTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      refinanceLoanType: REFINANCE_LOAN_TYPE,
      tableHeaders: [],
      tableRows: [],
      tooltipIndex: null,
      tooltipObject: {},
      payingOffDebts: true,
    };
  },
  watch: {
    showDebtTooltip() {
      this.createTableRows();
    },
    payingOffDebts() {
      this.createTableRows();
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.createTableHeaders();
      this.createTableRows();
    });
  },
  methods: {
    createTableHeaders() {
      this.tableHeaders = [];

      this.allScenarios.forEach((rec) => {
        let headObj = {
          name: rec.name,
          isCurrentMortgage: rec.currentMortgage,
        };

        this.tableHeaders.push(headObj);
      });

      this.tableHeaders.unshift({
        name: "Payment breakdown",
        isCurrentMortgage: false,
      });
    },

    createTableRows() {
      this.tableRows = [];
      let allKeys = [];

      for (let i = 0; i < this.allScenarios.length; i++) {
        if (i === 0) {
          allKeys = Object.keys(this.allScenarios[i]?.paymentBreakdown);
        }
      }

      allKeys = this.matchArraySequence(allKeys);

      let extraDebtDetails = this.splitArrayIntoObjects(
        this.getDebtDetailsScenarios
      );

      allKeys.forEach((item) => {
        if (
          item === "additionalDebts" &&
          (!this.showDebtDetails || !this.payingOffDebts)
        ) {
          return;
        }

        let obj = {
          0: this.convertText(item),
        };

        for (let j = 1; j < this.allScenarios.length + 1; j++) {
          this.$set(obj, j, this.allScenarios[j - 1]?.paymentBreakdown[item]);
        }

        if (this.getAssets?.loanPurposeType === REFINANCE_LOAN_TYPE) {
          if (item === "purchasePrice") obj[0] = "Property Value";
          if (item === "pi") obj[0] = "P&I (1st)";
        }

        if (item === "additionalDebts" && this.showDebtTooltip) {
          const allObjects = [obj, ...extraDebtDetails];
          let nestedObj = this.mergeObjects(...allObjects);
          this.tableRows.push(nestedObj);
        } else {
          this.tableRows.push(obj);
        }
      });

      // const check = this.tableRows.findIndex((el) => el[0] === "Extra Payment");

      // if (Object.values(this.tableRows[check]).indexOf(0) > -1) {
      //   this.tableRows.splice(check, 1);
      // }
    },

    fetchTooltipDetails(name, index) {
      this.tooltipIndex = index;
      this.tooltipObject = this.getDebtTooltip.find(
        (rec) => rec.creditor === name
      );
    },

    mergeObjects(...objects) {
      const result = {};
      const keys = Object.keys(objects[0]);

      keys.forEach((key) => {
        result[key] = {};

        objects.forEach((obj, index) => {
          result[key][index] = obj[key];
        });
      });

      return result;
    },

    matchArraySequence(array_two) {
      const array_one = [
        "purchasePrice",
        "pi",
        "propertyTaxes",
        "hoi",
        "pmi",
        "monthlyPayment",
        "hoa",
        "extraPayment",
        "additionalDebts",
        "totalPayment",
      ];

      const indexMap = array_one.reduce((map, val, index) => {
        map[val] = index;
        return map;
      }, {});

      return array_two.slice().sort((a, b) => {
        return indexMap[a] - indexMap[b];
      });
    },

    convertText(text) {
      if (!/[A-Z]/.test(text)) {
        return text.toUpperCase();
      }

      return text
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
    },

    splitArrayIntoObjects(array) {
      let result = [];
      let totalElements = array.length;

      let minElementsPerObject = Math.floor(
        totalElements / this.getDebtRecords
      );

      let startIndex = 0;

      for (let i = 0; i < this.getDebtRecords; i++) {
        let obj = {};

        for (let j = 0; j < minElementsPerObject; j++) {
          this.$set(obj, j, array[startIndex++]);
        }

        result.push(obj);
      }

      return result;
    },

    convertIfNumber(value) {
      if (!isNaN(value) && value !== "") {
        return parseFloat(value);
      }

      return value;
    },
  },
  computed: {
    ...mapGetters(["getAssets"]),
    ...mapGetters("mortgage-estimate", [
      "getDebtDetailsScenarios",
      "getDebtRecords",
      "getDebtTooltip",
    ]),

    showDebtDetails() {
      return this.getDebtRecords > 0;
    },

    backgroundTotal() {
      return this.$localStore.get("primary-color")
        ? this.hexToRGBA(this.$localStore.get("primary-color"), 0.2)
        : "#FFCCCF";
    },

    colorTotal() {
      return this.$localStore.get("primary-color");
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}
.tooltip {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  opacity: 1;
  transition: opacity 0.3s;
  width: 150px;
  max-width: 150px;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -20px;
  bottom: 102%;
}
.tooltip::after {
  content: "";
  border-width: 5px;
  position: absolute;
  border-style: solid;
  left: 23px;
  top: 100%;
  border-color: #fff transparent transparent transparent;
}
.button-switch {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
}
.button-switch .switch-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.28px;
  color: #000000;
}
.button-switch .switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 43px;
  height: 28px;
}

.button-switch .switch input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  cursor: pointer;
}

.button-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dadada;
  transition: 0.4s;
}
.button-switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.button-switch input:checked + .slider {
  background-color: v-bind(colorTotal);
}

.button-switch input:checked + .slider:before {
  transform: translateX(14px);
}

.button-switch input:focus + .slider {
  box-shadow: 0 0 1px v-bind(colorTotal);
}

.button-switch .slider.round {
  border-radius: 34px;
}
.button-switch .slider.round:before {
  border-radius: 50%;
}
</style>

<style lang="scss">
.justify-space-between {
  justify-content: space-between;
  align-items: center;
}
.add-margin-top {
  margin-top: 5px;
}
.extra-debt-details {
  font-weight: normal;
  margin-top: 3px;
}
@mixin v-padding {
  padding-left: v-bind(paddingX);
  padding-right: v-bind(paddingX);
  padding-top: v-bind(paddingY);
  padding-bottom: v-bind(paddingY);
}
@mixin base-table-properties {
  position: relative;
  top: 0;
  opacity: 0;
  transition: transform 1s, opacity 0.5s;
}

@media all and (max-width: 1099px) {
  .more-info-content:not(.layout-pdf) .more-info-payment-breakdown-table {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    &::-webkit-scrollbar {
      width: 7px !important;
      height: 7px !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #15162b !important;
      border: 5px solid #15162b !important;
      border-radius: 5px;
    }
  }
  .more-info-content:not(.layout-pdf)
    .more-info-payment-breakdown-table
    table
    thead
    tr
    th:first-child,
  .more-info-content:not(.layout-pdf)
    .more-info-payment-breakdown-table
    table
    tbody
    tr
    td:first-child,
  .fee-detail:not(.layout-pdf)
    .more-info-payment-breakdown-table
    table
    thead
    tr
    th:first-child,
  .fee-detail:not(.layout-pdf)
    .more-info-payment-breakdown-table
    table
    tbody
    tr
    td:first-child {
    padding-right: 16px !important;
    position: sticky !important;
    left: 0 !important;
    background: #fff !important;
    z-index: 999;
  }
}

@media all and (max-width: 899px) {
  .more-info-content:not(.layout-pdf) .more-info-payment-breakdown-table {
    margin-bottom: 24px !important;
    padding-bottom: 10px;
  }
}

@media all and (max-width: 599px) {
  .more-info-content:not(.layout-pdf)
    .more-info-payment-breakdown-table:not(.fee-detail--table)
    table
    thead
    tr
    th:not(:first-child)
    div {
    position: absolute;
    width: 118px;
    bottom: 20px;
  }
  .more-info-content:not(.layout-pdf)
    .more-info-payment-breakdown-table:not(.fee-detail--table)
    table
    thead
    tr
    th:not(:first-child) {
    min-width: 150px;
  }
  .more-info-content:not(.layout-pdf)
    .more-info-payment-breakdown-table
    table
    thead
    tr
    th:first-child
    div {
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: initial;
  }
  .more-info-content:not(.layout-pdf)
    .more-info-payment-breakdown-table
    table
    thead
    tr
    th:first-child {
    padding-right: 16px !important;
  }
}

.more-info-payment-breakdown-table .custom-badge {
  padding: 5px 8px;
  border-radius: 3px;
  color: v-bind(colorTotal);
  font-weight: 600;
  background-color: v-bind(backgroundTotal);
}

.more-info-payment-breakdown-table {
  margin-bottom: 8px;
  table {
    margin-bottom: 0;
    thead {
      opacity: 0;
      tr {
        th {
          @include v-padding;
          color: #000000b3;
          text-align: left;

          &:not(:first-child) {
            max-width: 150px;

            div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          &:first-child {
            padding-right: 66px !important;
            padding-left: 0;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #000000;
            div {
              max-width: 210px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
    tbody {
      @include base-table-properties;
      transform: translateY(-5%);
      tr {
        box-shadow: none;
        border: none;
        td {
          @include v-padding;
          border-top: 1px solid #d7dae3;
          border-bottom: none;
          &:first-child {
            border-radius: 0;
            padding-left: 0;
            border-left: 0;
            font-weight: 600;
          }
          &:last-child {
            border-radius: 0;
            padding-right: 0;
            border-right: 0;
          }
        }
      }
    }

    tfoot {
      @include base-table-properties;
      transform: translateY(-45%);
      td {
        @include v-padding;
        border-top: 1px solid #d7dae3;
        border-bottom: none;
        svg {
          overflow: inherit;
          padding: 0;
        }
        &:not(:first-child) {
          padding-left: 8px;
          span {
            text-decoration: underline;
            font-weight: 500;
            color: #200e00;
            margin-right: 8px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        span {
          padding: 0;
        }
        &:first-child {
          border-radius: 0;
          padding-left: 0;
          border-left: 0;
          font-weight: 600;

          position: sticky !important;
          left: 0 !important;
          background: #fff !important;
          z-index: 999;
        }
        &:last-child {
          border-radius: 0;
          padding-right: 0;
          border-right: 0;
        }
      }
    }
  }

  #loan-purpose-refinance-table {
    border-spacing: 0;
    .add-dynamic-border-head {
      // background-color: #f2f5fa;
      border-radius: 10px 10px 0 0;
      padding-left: 20px;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      border-bottom: none;
      font-weight: 600;
    }
    .add-dynamic-border-body {
      // background-color: #f2f5fa;
      padding-left: 20px;
      border: 1px solid var(--primary-color);
      border-top: 1px solid #d7dae3;
      border-bottom: none;
    }
    .add-dynamic-border-body-last-el {
      border-radius: 0 0 10px 10px;
      border-bottom: 1px solid var(--primary-color);
    }
  }

  .paid-by-col {
    color: #15162b;
    padding: 5px 14px;
    background: rgba(21, 22, 43, 0.05);
    border-radius: 3px;
    font-weight: 600;
  }

  .status-no {
    opacity: 0.6;
  }

  .green-fonts {
    font-weight: 500;
    color: #04844b;
  }
}

.table-from-bottom {
  transform: translateY(5%) !important;
}

.foot-from-bottom {
  transform: translateY(45%) !important;
}

.more-info-payment-breakdown-table-active {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.thead-info-active {
  opacity: 1 !important;
}
</style>
